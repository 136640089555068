<script>
import {defineComponent} from "vue";
import moment from "moment/moment";
import Table from "@/components/tables/table.vue"
import axios from "axios";
import Swal from "sweetalert2";
import {useRoute, useRouter} from "vue-router";
import PrintingListModal from "@/views/pages/smgs/modal/PrintingListModal.vue";
import CreateSmgsButton from "@/views/pages/smgs/components/CreateSmgsButton.vue"

export default defineComponent({
  name: '',
  components: {
    Table,
    PrintingListModal,
    CreateSmgsButton
  },
  data() {
    let router = useRouter()
    let route = useRoute()
    return {
      route: route,
      router: router,
      table: {
        name: 'SMGS PRINTING LIST',
        url: '/smgs_print/trains/list/',
        headers: [
          {
            label: 'TRAIN',
            field: 'name',
            align: 'center',
          },
          {
            label: 'SMGS COUNT',
            field: 'smgs_count',
            align: 'center',
          },
          {
            label: 'DRAFT',
            field: 'excel_draft',
            align: 'center',
          },
          {
            label: 'ORIGINAL',
            field: 'excel_original',
            align: 'center',
          },
          {
            label: 'BOTH',
            field: 'excel_all',
            align: 'center',
          },
          {
            label: 'EXCEL FILE',
            field: 'excel_file',
            align: 'center',
          },
          {
            label: 'DATE CREATED',
            field: 'created',
            align: 'center',
          },
          {
            label: 'DATE MODIFIED',
            field: 'modified',
            align: 'center',
          },
          {
            label: 'MANAGER',
            field: 'user',
            align: 'center',
          },
        ]
      }
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return '-'
      if (moment(date).format('YYYY') === (new Date().getFullYear()).toString()) {
        return moment(date).format('MMMM Do, h:mm:ss a');
      }
      return moment(date).format('MMMM Do YYYY, h:mm:ss a');
    },

    async downloadSMGSFile(train_id, train_name, file_type) {
      try {
        const response = await axios.get(`/smgs_print/trains/${train_id}/smgs-zip/`, {
          responseType: 'arraybuffer',
          params: {
            doc_type: file_type ? file_type : undefined
          }
        });
        const blob = new Blob([response.data], {type: 'application/zip'});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = file_type ? `${train_name}_${file_type}.zip` : `${train_name}.zip`;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (error) {
        await Swal.fire({
          icon: 'error',
          title: `${train_name}.zip`,
          text: 'Error downloading the file'
        })
      }
    },

    async downloadSMGSExcel(excel_file_path) {
      if (!excel_file_path) return;
      let url = process.env.VUE_APP_ORDER_URL + excel_file_path;
      fetch(url)
          .then((resp) => resp.blob())
          .then((blobobject) => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.href = blob;
            anchor.download = url.toString().split("/")[
            url.toString().split("/").length - 1
                ];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert("An error in downloading the file sorry"));
    },

    async showTrainSMGSList(train_id, train_name) {
      await this.router.push({
        query: {
          ...this.route.query,
          show_smgs_list: true,
          selected_train: train_id + '~' + train_name
        }
      })
    }
  }
})
</script>
<template>
  <PrintingListModal/>
  <Table v-bind="table" ref="table">

    <template v-slot:top-right>
      <CreateSmgsButton @onSuccess="this.$refs.table.getData()"/>
    </template>

    <template v-slot:name="{row: data}">
      <span @click="showTrainSMGSList(data.id, data.name)"
            class="text-decoration-underline link-primary cursor-pointer">
        {{ data.name }}
      </span>
    </template>
    <template v-slot:smgs_count="{row: data}">
      <span class="badge border border-success text-success">{{ data.smgs_count || 0 }}</span>
    </template>
    <template v-slot:created="{row: data}">
      {{ formatDate(data.created) }}
    </template>
    <template v-slot:modified="{row: data}">
      {{ formatDate(data.modified) }}
    </template>
    <template v-slot:user="{row: data}">
      {{ data.user.full_name ? data.user.full_name : data.user.username }}
    </template>
    <template v-slot:excel_file="{row: data}">
      <font-awesome-icon
          @click="downloadSMGSExcel(data.excel_file)"
          icon="fa-solid fa-file-excel p-0 m-0"
          class="c_icon_hoverable text-success"
      />
    </template>
    <template v-slot:excel_draft="{row: data}">
      <font-awesome-icon
          @click="downloadSMGSFile(data.id, data.name, 'draft')"
          icon="fa-solid fa-file-zipper"
          class="c_icon_hoverable zip_file"
      />
    </template>
    <template v-slot:excel_original="{row: data}">
      <font-awesome-icon
          @click="downloadSMGSFile(data.id, data.name, 'original')"
          icon="fa-solid fa-file-zipper"
          class="c_icon_hoverable zip_file"
      />
    </template>
    <template v-slot:excel_all="{row: data}">
      <font-awesome-icon
          @click="downloadSMGSFile(data.id, data.name)"
          icon="fa-solid fa-folder"
          class="c_icon_hoverable text-warning"
      />
    </template>
  </Table>
</template>