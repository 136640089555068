<script>
import axios from "axios";
import Skeleton from "@/components/custom/skeleton.vue"

export default {
  name: "PrintingListModal",
  components: {Skeleton},
  data() {
    return {
      showModal: false,
      train_id: null,
      train_name: '',
      smgs_list: [],
      items_per_page: 25,
      current_page: 1,
      total_pages: 1,
      is_loading: false
    }
  },
  computed: {
    show_smgs_list() {
      if (this.$route.query.show_smgs_list) {
        return JSON.parse(this.$route.query.show_smgs_list) === true
      }
      return false
    }
  },
  methods: {
    async getTrainSMGSList() {
      this.smgs_list = []
      this.total_pages = 1
      try {
        this.is_loading = true
        let response = await axios.get(`/smgs_print/list/${this.train_id}/`, {
          params: {
            offset: (this.current_page - 1) * this.items_per_page,
            limit: this.items_per_page
          }
        })
        this.smgs_list = response.data.results || []
        this.total_pages = Math.ceil(response.data.count / this.items_per_page)
        this.is_loading = false
      } catch {
        this.is_loading = false
        alert("error")
      }
    },

    async downloadSMGSFile(file_path) {
      if (!file_path) return;
      let url = process.env.VUE_APP_ORDER_URL + file_path;
      fetch(url)
          .then((resp) => resp.blob())
          .then((blobobject) => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement("a");
            anchor.style.display = "none";
            anchor.href = blob;
            anchor.download = url.toString().split("/")[
            url.toString().split("/").length - 1
                ];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert("An error in downloading the file sorry"));
    },
  },
  watch: {
    show_smgs_list: {
      handler(newValue) {
        if (newValue && this.$route.query.selected_train) {
          try {
            this.train_id = this.$route.query.selected_train.split('~')[0]
            this.train_name = this.$route.query.selected_train.split('~')[1]
          } catch {
            alert("Could not process your request")
          }
        }
      },
      deep: true,
      immediate: true
    },
    train_id: {
      handler(newValue) {
        if (newValue) {
          this.getTrainSMGSList()
          this.showModal = true
        } else {
          this.showModal = false
        }
      },
      deep: true,
      immediate: true
    },
    showModal: {
      handler() {
        if (!this.showModal) {
          let existing_query = {
            ...this.$route.query,
          }
          delete existing_query['show_smgs_list']
          delete existing_query['selected_train']
          this.train_id = null
          this.train_name = ''
          this.$router.push({query: existing_query})
        }
      },
    },
    current_page() {
      if (this.train_id) {
        this.getTrainSMGSList()
      }
    }
  }
}
</script>

<template>
  <b-modal v-model="showModal" :title="train_name" hide-footer centered size="lg">

    <div>
      <ul class="pagination pagination-sm pagination-separated">

        <li class="page-item" :class="{
          'disabled': current_page === 1
        }"
            @click="current_page = current_page > 1 ? current_page - 1 : 1"
        >
          <a class="page-link">←</a>
        </li>

        <li v-for="page in total_pages" :key="`smgs_page_${page}`" class="page-item" :class="{
          'active': current_page === page
        }"
        >
          <a @click="current_page = page" class="page-link cursor-pointer">{{ page }}</a>
        </li>

        <li class="page-item" :class="{
          'disabled': current_page === total_pages
        }" @click="current_page = total_pages === current_page ? current_page : current_page + 1"
        >
          <a class="page-link">→</a>
        </li>
      </ul>
    </div>

    <table class="table table-sm table-nowrap">
      <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Container number</th>
        <th scope="col" class="text-center">Draft</th>
        <th scope="col" class="text-center">Original</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="(smgs, index) in (is_loading ? 25 : smgs_list)" :key="`smgs_${smgs}`">
        <tr class="align-middle">
          <th scope="row" class="text-muted fs-11">{{ index + 1 }}</th>
          <td>
            <Skeleton v-if="is_loading" style="max-width: 110px"/>
            <span v-else>{{ smgs.container }}</span>
          </td>
          <td class="text-center">
            <Skeleton v-if="is_loading" style="max-width: 75px" class_list="mx-auto"/>
            <div v-else class="d-flex align-items-center gap-1 justify-content-center">
              <img src="@/assets/images/smgs-draft.png" style="height: 21px; width: 21px">
              <h6 @click="downloadSMGSFile(smgs.file)" class="mb-0 fs-11 text-decoration-underline cursor-pointer">
                Download</h6>
            </div>
          </td>
          <td class="text-center">
            <Skeleton v-if="is_loading" style="max-width: 75px" class_list="mx-auto"/>
            <div v-else class="d-flex align-items-center gap-1 justify-content-center">
              <img src="@/assets/images/smgs-original.png" style="height: 21px; width: 21px">
              <h6 @click="downloadSMGSFile(smgs.file_original)"
                  class="mb-0 fs-11 text-decoration-underline cursor-pointer">Download</h6>
            </div>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </b-modal>
</template>

<style scoped>

</style>